<template>
  <div class="model-maintenance">
    <smartx-model-maintenance
      :token="token"
      :url="baseUrl"
    ></smartx-model-maintenance>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ModelMaintenance",
  data() {
    return {
      token: ""
    };
  },
  async mounted() {
    let token = this.$cookies.get("token");
    if (token && token.startsWith("Bearer ")) {
      token = token.replace("Bearer ", "");
    }
    this.token = token;
  },
  computed: {
    ...mapState({
      baseUrl: state => state.endpoints.baseUrl
    })
  }
};
</script>
